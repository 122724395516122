<template>
  <div class="information">
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="title">
          <div class="image" @click="shouye">
            <el-carousel
              height="80px"
              direction="vertical"
              arrow="never"
              indicator-position="none"
              :interval="5000"
            >
              <el-carousel-item>
                <img src="../assets/img/logo.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo2.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo2.png" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="titleBtn">
            <div @click="shouye">首页</div>
            <div @click="shanpin">产品中心</div>
            <div class="active" @click="zixun">资讯动态</div>
            <div @click="zhaoxian">招贤纳士</div>
            <div @click="yuedong">走进跃动</div>
            <div @click="lianxi">联系我们</div>
          </div>
        </div>
      </el-header>
      <!-- 主体 -->
      <el-main>
        <div class="informationback">
          <div>NEWS</div>
          <div>资讯动态</div>
        </div>
        <div class="informationCard">
          <div
            class="card"
            v-for="item of data"
            :key="item.Id"
            @click="tiaozhuan(item.Id)"
          >
            <img :src="'https://' + item.imgUrl" />
            <div class="cardtext">
              <div class="cardtext1">{{ item.newsTitle }}</div>
              <div class="cardtext2">{{ item.guide }}</div>
              <div class="cardfoot">
                <div class="date">
                  <img src="../assets/img/date.png" />
                  <div class="datediv">{{ item.timeDate }}</div>
                </div>
                <div class="info">
                  <div class="infodiv">查看详情</div>
                  <img src="../assets/img/right3.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <footerComponents></footerComponents>
    </el-container>
  </div>
</template>
<script>
import footerComponents from "../components/footer.vue";
export default {
  name: "Information",
  components: { footerComponents },
  data() {
    return {
      data: [],
    };
  },
  mounted() {
	  if(/Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)) {
	  	//移动设备打开的网页，缩放页面尺寸
	  	/*
	  	var clientWidth = document.body.clientWidth;
	  	var rate = clientWidth / 1920;
	  	*/
	     var rate = 0.1;
	  	var meta = document.getElementsByTagName('meta');
	  	meta['viewport'].setAttribute('content', 'width=device-width,initial-scale='+rate);
	  }
    this.$http.get("/api/GetAllNews").then((res) => {
      let a = JSON.parse(res.data);
      console.log(a[0]);
      if (a[0].code == "success") {
        this.data = a[0].data;
      }
    });
  },
  methods: {
    //跳转首页
    shouye() {
      this.$router.push("/");
    },
    //跳转产品中心
    shanpin() {
      this.$router.push("/product");
    },
    //跳转资讯动态
    zixun() {
      this.$router.push("/information");
    },
    //跳转招贤纳士
    zhaoxian() {
      this.$router.push("/careers");
    },
    //跳转走进跃动
    yuedong() {
      this.$router.push("/yedone");
    },
    //联系我们
    lianxi() {
      this.$router.push("/contactUs");
    },
    //点击跳转资讯详情页
    tiaozhuan(id) {
      this.$router.push({ name: "Dynamic", query: { id: id } });
    },
  },
};
</script>
<style lang="less" scoped>
// 页头样式
.el-header {
  width: 100%;
  min-width: 1480px;
  padding: 0;
  margin: 0 auto;
  height: 80px !important;
  line-height: 80px;
  .title {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    .image {
      min-width: 158px;
      height: 80px;
      margin-right: 52px;
      img {
        width: 158px;
        height: 40px;
        padding-top: 20px;
      }
      &:hover{
        cursor: pointer;
      }
    }
    .titleBtn {
      display: flex;
      align-items: center;
      height: 80px;
      box-sizing: border-box;
      > div {
        margin-left: 68px;
        width: 72px;
        height: 27px;
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 27px;
        color: #666666;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }
      .active {
        position: relative;
        color: #004097;
      }
      .active::after {
        position: absolute;
        bottom: -10px;
        content: "";
        width: 30px;
        height: 4px;
        background: #004097;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }
}
.el-main {
  width: 100%;
  min-width: 1480px;
  margin: 0 auto;
  padding: 0;
  .informationback {
    height: 620px;
    background-image: url(../assets/img/informationback.png);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    padding-top: 220px;
    div:nth-child(1),
    div:nth-child(2) {
      width: 1200px;
      margin: 0 auto;
      font-size: 48px;
      font-family: SF Display;
      font-weight: normal;
      line-height: 48px;
      color: #ffffff;
    }
    div:nth-child(2) {
      margin-top: 10px;
      font-family: Source Han Sans SC;
      font-weight: 500;
    }
  }
  .informationCard {
    box-sizing: border-box;
    padding-top: 30px;
    padding-bottom: 60px;
    background: #f4f5f9;
    .card {
      width: 1200px;
      height: 320px;
      box-sizing: border-box;
      padding-top: 40px;
      background: #ffffff;
      opacity: 1;
      border-radius: 12px;
      margin: 0 auto;
      display: flex;
      &:hover {
        cursor: pointer;
        box-shadow: 0px 5px 20px rgba(90, 97, 107, 0.1);
        .cardtext {
          .cardtext1,
          .infodiv {
            color: #004097;
          }
        }
      }
      &:not(:first-child) {
        margin-top: 30px;
      }
      > img {
        width: 360px;
        height: 240px;
        margin-left: 40px;
        border-radius: 8px;
      }
      .cardtext {
        margin: 0 40px;
        position: relative;
        font-family: Source Han Sans SC;
        .cardtext1 {
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
          color: #161e2a;
        }
        .cardtext2 {
          width: 720px;
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          color: #626778;
          margin-top: 20px;
        }
        .cardfoot {
          width: 720px;
          position: absolute;
          bottom: 54px;
          display: flex;
          justify-content: space-between;
          .date,
          .info {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            color: #8c97a8;
            .datediv {
              margin-left: 8px;
            }
            .infodiv {
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
}
</style>
